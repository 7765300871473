import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ServiceComponent } from './service/service.component';
import { BenefitComponent } from './benefit/benefit.component';
import { AgentComponent } from './agent/agent.component';
import { SureComponent } from './sure/sure.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { QuestionComponent } from './question/question.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { TestComponent } from './test/test.component';
import { SegumovilComponent } from './segumovil/segumovil.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  { path: 'inicio', component: AboutComponent },
  { path: 'servicios', component: ServiceComponent },
  { path: 'beneficios', component: BenefitComponent },
  { path: 'agentes', component: AgentComponent },
  { path: 'seguros', component: SureComponent },
  { path: 'glosario', component: GlossaryComponent },
  { path: 'preguntas', component: QuestionComponent },
  { path: 'preguntas/:id', component: QuestionComponent },
  { path: 'contacto', component: ContactComponent },
  { path: 'login', component: LoginComponent },
  { path: 'prueba', component: TestComponent },
  { path: 'segumovil', component: SegumovilComponent },
  { path: 'administrador', component: AdminComponent },
  { path: '**', redirectTo: 'inicio', pathMatch: 'full' },
  { path: '', redirectTo: 'inicio', pathMatch: 'full' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
