import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings = {
    'token':'',
    'questions': {}
  }
  constructor() {
    this.loadSettings();
  }

  loadSettings(){
    if(localStorage.getItem('settings')){
      this.settings = JSON.parse(localStorage.getItem('settings'));
    }
  };

  saveSettings(){
    localStorage.setItem('settings', JSON.stringify(this.settings));
  };

  deleteSettings(){
    this.settings.token = null;    
    this.settings.questions = null;    
    this.saveSettings();
  }
}
