import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  color = '#0071b3';
  borderAbout;
  borderService;
  borderBenefit;
  borderGuide;
  borderQuestion;
  borderContact;

  constructor(
  	private router: Router,
  	private location: Location
  ) { 
  	router.events.subscribe(val => {
  	  switch (location.path()) {
    		case '/inicio':
          this.borderAbout = '2px solid ' + this.color;
          break;
        case '/servicios':
          this.borderService = '2px solid ' + this.color;
          break;
        case '/beneficios':
          this.borderBenefit = '2px solid ' + this.color;
          break;
        case '/agentes':
          this.borderGuide = '2px solid ' + this.color;
          break;
        case '/seguros':
          this.borderGuide = '2px solid ' + this.color;
          break;
        case '/glosario':
          this.borderGuide = '2px solid ' + this.color;
          break;
        case '/preguntas':
          this.borderQuestion = '2px solid ' + this.color;
          break;
        case '/contacto':
    		  this.borderContact = '2px solid ' + this.color;
    		  break;
  	    default:
  		    break;
  	  }
    });
  }

  ngOnInit() {
  }

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

}
