import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from '../provider/rest-api';

@Component({
  selector: 'app-sure',
  templateUrl: './sure.component.html',
  styleUrls: ['./sure.component.css']
})
export class SureComponent implements OnInit {

  showInformation = false;
  showError = false;
  cards:any = [];

  title = {};
  comment = {};
  subtitles:any = [];
  comments:any = [];

  constructor(
    public _api: RestApiProvider
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.goToAdmin();
    this.getCards();
  }

  goToAdmin(){
    this._api.login('superuser_gpi', 'Gpisuperuser2021').then(response=>{
      if(response['token_jwt']){
        this.getCards();
      }
      if(response['error']){
      }
    }).catch(error=>{
      console.log(error);
    })
  };

  getCards(){
    this._api.get_cards('Polizas%20de%20Seguros').then(response=>{
      if(response){
        for (let i = 0; i < response['length']; i++) {
          if(response[i]['category'] == 'Polizas de Seguros'){
            this.cards.push(response[i]);
          }
        }
      }
    }).catch(error=>{
      console.log(error);
    })
  };

  goToInformation(a){
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
    if (a==1) {
      this.title = '¿Qué es y para qué sirve un seguro de Gastos Médicos?';
      this.subtitles = '<p>Los seguros de Gastos Médicos protegen nuestra '+
      'economía en caso de que pase alguno de los eventos para los que los contratamos.</p>'+
      '<p>Nos guste o no, todos somos propensos a que nos suceda algún tipo de accidentes o '+
      'nos dé alguna enfermedad que pueda ocasionar un alto gasto imprevisto para nuestro bolsillo. '+
      'Citando el famoso refrán “más vale prevenir que lamentar”, el seguro médico es una herramienta '+
      'financiera que nos sacará de apuros cuando nosotros o alguno de nuestros seres queridos enferme '+
      'o tenga un accidente. Por ello, se suele recomendar que las personas cuenten con alguno, '+
      'adicional al seguro que brindan las instituciones públicas.</p>' +
      '<p><b>¿Qué es?</p></b>' +
      '<p>Un seguro de gastos médicos mayores cubre los gastos generados en caso de un accidente o una '+
      'enfermedad según lo previsto en el contrato. Las coberturas de tu póliza de seguros varían de '+
      'acuerdo a la compañía que contrates.</p>' +
      '<p>Pero en general,  un seguro de gastos médicos puede abarcar gastos de hospitalización, ' +
      'atención médica, intervenciones quirúrgicas, medicamentos y análisis clínicos, entre otros ' +
      'servicios profesionales; todo dependerá de la compañía aseguradora y la póliza que contrates.</p>' +
      '<p>Es muy importante que leas con mucho cuidado todas las especificaciones y los requisitos de ' +
      ' tu póliza, ya que; hay acciones o situaciones que invalidan la póliza de seguro, al igual que, '+
      'existen muchos servicios de tu póliza que los asegurados no utilizan porque desconocen su ' +
      'existencia, como el servicio médico a domicilio, medicina alternativa, tratamientos dentales ' +
      'básicos o atención psicológica, entre otros. </p>' +
      '<p>Considera que los seguros tienen antigüedad, es decir, no cubren de manera inmediata al '+
      'asegurado, así que mientras más pronto lo obtengas, es mejor para ti.</p>' +
      '<p>Debes saber que a la hora de contratar tu seguro te harán preguntas sobre tu salud, para ' +
      'saber si tienes algún padecimiento o enfermedades preexistentes antes de tu póliza. Siempre di ' +
      'la verdad sobre este aspecto, ya que de dar una respuesta falsa, la validez de tu póliza se verá ' +
      'afectada.</p>' +
      '<p><b>Pequeño diccionario de términos</b></p>' +
      '<p>Sabemos que desconoces muchos de los conceptos de tu póliza, por ello hemos creado una lista con los términos más frecuentes.</p>' +
      '<p>Prima. Se refiere a la cantidad que debes pagar a tu compañía aseguradora para mantener tu ' +
      ' cobertura, la de tus familiares o la de tus empleados.</p>' +
      '<p>Copago. Es una pequeña suma de dinero abonada cada vez que se utiliza un servicio médico. Algunas pólizas solicitan el copago ' +
      'por una visita médica o por un medicamento con receta, aunque la aseguradora pague el resto después.</p>' +
      '<p>Deducible. Es el monto que tú, tus familiares o empleados deberán pagar antes de que la ' +
      'aseguradora reembolse esa cantidad por reclamos médicos procedentes.</p>' +
      '<p>Coaseguro. Es la cantidad que tus colaboradores deben pagar por los servicios ' +
      'médicos después de cubrir el copago o deducible de su plan de seguro. </p>' +
      '<p>Asesórate y contrata un Seguro de Gastos Médicos, ¡que no te agarren desprevenido! </p>';
    } else if (a==2) {
      this.title = '¿Qué es y para qué sirve un seguro de autos?';
      this.subtitles = '<p>Regularmente cuando buscamos cómo asegurar nuestro auto, las preguntas más comunes que nos hacemos son ¿cuánto cuesta? ' +
      'o ¿qué abarca un seguro de autos? Para responder lo anterior, lo primero que debemos saber es qué es un ' +
      'seguro de auto, su cobertura y los tipos de seguro que hay.</p>' +
      '<p>Un seguro de auto es un contrato entre el propietario de un vehículo y una aseguradora cuyo objetivo es cubrir ' +
      'económicamente los riesgos asociados a la conducción y tenencia de dicho  vehículo, como choques, robo, ' +
      'daños y otros servicios, así como protección a tus pasajeros y a terceros afectados en un accidente.</p>' +
      '<p><b>Características de un seguro de auto</b></p>' +
      '<p>Si bien, los seguros de auto pueden variar, tienen en común ciertas características como:</p>' +
      '<p><b>Cobertura.</b> Al momento de adquirir tu póliza de seguros tienes la opción de escoger qué tipo de cobertura elegir.</p>' +
      '<p><b>Pago máximo</b>. Este se refiere al valor total que cubrirá tu póliza de seguros. Por ejemplo, si los daños por un accidente en tu ' +
      'automóvil equivalen a 500 mil pesos y tu póliza de seguro tiene un tope de 400 mil, los 100 mil pesos restantes deberán correr por ' +
      'tu cuenta.</p>' +
      '<p><b>Términos de reclamación.</b> Aquí hablamos del procedimiento que deberás realizar para que la aseguradora te haga el '+
      'reembolso del dinero.</p>' +
      '<p>Tipos de seguro de auto</p>' +
      '<p>Los tipos de seguro de auto pueden clasificarse según su cobertura: amplia, limitada o de Responsabilidad Civil.</p>' +
      '<p><b>Responsabilidad Civil</b></p>' +
      '<p>En México existe por ley un seguro básico que es de carácter obligatorio y que cubre la Responsabilidad Civil del conductor o del ' +
      'propietario del vehículo en caso de perjudicar a algún tercero usando dicho auto. A partir del 1° de enero de 2019, es obligatorio ' +
      'tener seguro de Responsabilidad Civil al transitar por Carreteras Federales en nuestro país.</p>' +
      '<p>Este tipo de seguro cubre los daños a terceros afectados por valor de 100,000 pesos y 50,000 adicionales por perjuicios f' +
      'ísicos a terceros ocupantes del vehículo involucrado en el accidente.</p>' +
      '<p>Por tanto, el seguro de responsabilidad civil no incluye coberturas contra daños del vehículo, asistencia vial o gastos legales.</p>' +
      '<p><b>Cobertura Limitada</b></p>' +
      '<p>Incluye las coberturas más básicas, pero tiene como prioridad cubrir los daños causados a terceros debido a algún accidente y el robo del vehículo asegurado.</p>' +
      '<p>Este tipo de seguro no cubre los daños al vehículo asegurado.</p>' +
      '<p><b>Cobertura Amplia </b></p>' +
      '<p>Cubre, no solo, la responsabilidad civil del conductor o del propietario, sino además la pérdida total del vehículo, la asistencia en carretera, ' +
      'los gastos médicos a los ocupantes, los gastos legales y algunos adicionales que estén incluidos en la póliza.</p>' +
      '<p>Recuerda que con un seguro de auto tu vehículo estará protegido y tú estarás más tranquilo.</p>';
    } else if (a==3) {
      this.title = '¿Qué es y cómo funciona un seguro de viaje?';
      this.subtitles = '<p>Siempre que salimos de viaje investigamos sobre el clima, aspectos generales de la zona, el hospedaje, para evitar tener ' +
      'contratiempos durante nuestra estancia. Pensando en los problemas que pueden surgir tanto antes como durante ' +
      ' el viaje, las aseguradoras ofrecen diversos tipos de seguro de viaje con el fin de asistir al viajero ante los mismos y ' +
      'sufragar los gastos ocasionados.</p>' + 
      '<p>Es decir, el seguro de viaje te asiste ante imprevistos durante el viaje, pero también antes de marcharte. Problemas de salud, con el ' +
      'equipaje, causas que te impidan realizar el viaje. ¿Alguna vez has considerado contratar alguno? Aquí te ' +
      'damos las razones por las cuales podría convenirte adquirirlo.</p>' +
      '<p>¿Qué cubre un seguro de viaje?</p>' + 
      '<p><b>Protección 24/7</b></p>' + 
      '<p>Sin duda el seguro de viajero es el mejor compañero durante tu estancia fuera de casa. Este tipo de seguros cuentan con servicio de ' +
      'asistencia 24 horas, por lo que podrán ayudarte a resolver cualquier conflicto durante tu viaje, sabiendo ' +
      'cómo actuar y qué documentación reunir para disfrutar de las coberturas de tu póliza.</p>' +
      '<p><b>Asistencia médica de calidad</b></p>' +
      '<p>Enfermar y recibir una asistencia sanitaria con calidad y rapidez es la mayor preocupación cuando viajamos. Sin importar dónde ' +
      'estés,este tipo de seguro te ofrece asistencia médica de calidad, y sin costes adicionales.</p>' +
      '<p><b>Ahorro</b></p>' +
      '<p>Si se retrasa tu vuelo o llegas a perder el check-in en tu hotel, el seguro de viaje correrá con ese tipo de gastos. Lo mismo ocurre con la ' +
      'asistencia sanitaria, un seguro médico como el que incluye tu póliza de viajero puede ayudarte a ahorrar mucho dinero, ' +
      'incluso, en el caso de solo necesitar una visita al médico.</p>' +
      '<p>El seguro de viaje te facilita las gestiones del viaje, sobre todo ante imprevistos e incidentes. Por ejemplo, en caso de que la aerolínea ' +
      'no encuentre tu equipaje, este tipo de póliza contempla coberturas para que puedas comprar los artículos de primera necesidad para continuar ' +
      'con tu viaje (ropa,productos de higiene personal, medicamentos).</p>' +
      '<p><b>Cancelaciones sencillas</b></p>' +
      '<p>Cuando planificas con mucha antelación puede ser contraproducente ya que reduce las posibilidades para prever cambios o situaciones que, ' +
      'finalmente, te impidan viajar: motivos laborales, de salud, familiares, etc. Los seguros de viajero cuentan ' +
      'con coberturas de cancelación, por lo que puedes anular tu viaje hasta un día antes de su inicio sin perder ' +
      'tu dinero.</p>' +
      '<p>De igual manera, en caso de tener que interrumpir nuestro viaje y volver precipitadamente, el seguro también se hace cargo del viaje de ' +
      'vuelta así como el reembolso de los días cancelados.</p>' +
      '<p><b>Información confiable y segura</b></p>' +
      '<p>Suelen ofrecer asistencia 24/7 para ayudarte a resolver cualquier consulta o duda que tengas antes, durante y después de tu viaje. ' +
      'Por ejemplo, desde cómo obtener tu visado hasta indicarte a dónde dirigirte si te enfermas, anular tus ' +
      'tarjetas en caso de robo o a solucionar si dejaste un objeto de valor en el hotel.</p>' +
      '<p><b>Regresa antes de tiempo</b></p>' +
      '<p>¿Necesitas regresar a casa antes de tiempo? Así como el seguro de viaje cubre los gastos médicos durante tu estancia en otros ' +
      'países, también cubre los gastos de búsqueda y rescate en caso de algún accidente así como la repatriación ' +
      'en caso de emergencia médica.</p>' +
      '<p><b>Contrata lo que necesites</b></p>' +
      '<p>Consulta con la aseguradora para adquirir el seguro de viaje más adecuado a tus necesidades. No son las mismas coberturas si ' +
      'te marchas de crucero que si te vas a la playa o vas a practicar deportes de riesgo. Para elegir la póliza ideal para ti es ' +
      'importante que consideres el destino y el tiempo que durará tu viaje.</p>' +
      '<p>Puedes recurrir a un seguro anual, si es que sales varias veces al año; uno deportivo, en caso que te gusten los deportes ' +
      'riesgosos; un seguro familiar, si es que viajas siempre con toda tu familia, entre otros.</p>' +
      '<p><b>Lo que debes saber sobre el seguro médico</b></p>' +
      '<p>Cobertura: El seguro médico sólo te cubrirá en aquellas situaciones relacionadas con la asistencia médica en el extranjero, ' +
      'mientras que el seguro de viaje puede cubrir la repatriación, problemas relacionados con el equipaje, la ' +
      'documentación, el transporte, el alojamiento o la cancelación del viaje.</p>' +
      '<p>Precio: Los seguros médicos suelen contratarse de manera anual, pero si solo deseas estar cubierto durante el ' +
      'tiempo de duración de tus viajes, es más rentable contratar un seguro de viajero, es decir, solo lo pagas el ' +
      'tiempo que lo necesitas.</p>' +
      '<p>Asistencia en el extranjero: Puedes saber, gracias a la aseguradora, a qué centro médico dirigirte y ' +
      'tendrás cubiertos los gastos médicos de inmediato, sin tener que esperar futuros reembolsos.</p>' +
      '<p>¡Ahora sí, ya puedes salir de viaje con toda la tranquilidad!</p>';
    } else if (a==4) {
      this.title = '¿Qué es y para qué sirve un seguro de vida?';
      this.subtitles = '<p>Un seguro de vida es una excelente idea cuando tienes familiares que dependen económicamente de ti.</p>'+
      '<p>El seguro de vida es aquel producto que protege a tu familia y a otras personas que dependen de tus ingresos económicos. ' +
      'Es decir, en caso de fallecimiento o incapacidad, la aseguradora con la que contrataste la póliza pagará una cantidad de dinero ' +
      'determinada a la persona(s) que hayas designado como beneficiarias.</p>' +
      '<p>Con regularidad, las personas que adquieren un seguro de vida suelen tener como fin garantizar que sus beneficiarios ' +
      'queden protegidos después de su fallecimiento y así cuenten con los recursos suficientes para mantener el mismo estilo de ' +
      'vida al que están acostumbrados a pesar de que el proveedor de recursos económicos falte.</p>' +
      '<p>Al contratar un seguro de vida, la persona puede designar a más de un beneficiario, y estos, podrán utilizar el dinero ' +
      'como ellos mejor lo decidan.</p>' +
      '<p><b>¿Cómo funciona un seguro de vida?</b></p>' +
      '<p>Un seguro de vida te da la tranquilidad de saber que tus seres queridos estarán protegidos económicamente en caso de ' +
      'fallecimiento o incapacidad.</p>' +
      '<p>Antes de elegir un seguro de vida es importante que pienses en las necesidades económicas de tu familia, esto te ayudará ' +
      'a elegir entre los distintos tipos de seguro: los que son de protección o aquellos que tienen un componente ' +
      'ahorro.</p>' +
      '<p>Una vez que ya lo hayas hecho, debes de elegir bien a tus beneficiarios, pues no se recomienda dejar a menores de edad, ' +
      'aunque puedes dejar como beneficiario a un mayor de edad de confianza que pueda cuidar de los intereses de ' +
      'los menores.</p>' +
      '<p>Las pólizas de seguro se componen por: </p>' +
      '<ul>' +
        '<li>Asegurador. Es la compañía de seguros con la que se firma el acuerdo.</li>' +
        '<li>Siniestro. Se refiere a los sucesos que provocan la activación de la cobertura; puede tratarse ' +
        'del fallecimiento o la incapacidad del asegurado.</li>' +
        '<li>Asegurado. Persona que contrató el seguro.</li>' +
        '<li>Beneficiario. Persona que recibirá la suma asegurada.</li>' +
        '<li>Prima de seguro. El dinero que paga el asegurado a la empresa a cambio de la cobertura.</li>' +
      '<ul>' +
      '<p><b>¿Cómo puedo adquirir un seguro de vida?</b></p>' +
      '<p>El seguro de vida se contrata a través de la adquisición de una póliza por la que se pagan cantidades establecidas ' +
      'de manera periódica (primas) y, cuando el asegurado fallece o queda incapacitado, la compañía de seguro ' +
      'pagará la cantidad asegurada a los beneficiarios.</p>' +
      '<p>Puedes adquirir un seguro de vida con aseguradoras, bancos y agentes. A menudo es necesario realizarse ' +
      'un examen médico así como responder algunas preguntas sobre tu salud, tu trabajo y tus hábitos.</p>' +
      '<p>El costo de las primas dependerá de diversos factores como edad, suma asegurada, estilo de vida, ' +
      'tipo de cobertura y las situaciones de riesgo a las que esté expuesto el contratante.</p>' +
      '<p>Es común que las primas sean más bajas para las personas más jóvenes e incluso pueden aumentar si fumas.</p>' +
      '<p>Antes de elegir un seguro de vida, no consideres descartar una póliza por la prima, enfócate ' +
      'en comparar entre coberturas y condiciones de uso.</p>' +
      '<p><b>¿Por cuánto dinero debo contratar un seguro de vida?</b></p>' +
      '<p>Lo cierto es que no hay una respuesta exacta en este tema, ya que hay diversos factores a considerar para ello. ' +
      'Lo primero que debes preguntarte es cuánto quieres que la compañía de seguros pague si te pasa algo. ' +
      'Considera que, cuanto más necesites, más pagarás por la póliza de tu seguro.</p>' +
      '<p>Lo ideal para determinar el monto sería calcular al menos cinco veces tu salario bruto anual, aunque ' +
      'es importante que lo analices analizando la situación particular de tu familia.</p>' +
      '<p>¿Estás listo para contratar un seguro de vida? </p>';
    } else if (a==5) {
      this.title = 'Seguros para emprendedores, ¿existen?';
      this.subtitles = '<p>Como todos sabemos, un emprendedor se expone a ciertos riesgos a partir del ' +
      'día en que decide comenzar su empresa incluso antes de que el primer empleado sea contratado.</p>' +
      '<p>Una demanda legal o un accidente son suficientes para destruir un negocio antes de que ' +
      'éste pueda reaccionar. Afortunadamente existen numerosos tipos de seguros que pueden salvar ' +
      'tu negocio y evitar que acabe con él y con tus sueños.</p>' +
      '<p>Si bien, los bienes que debe asegurar una empresa dependen del giro de la misma, existen ' +
      'algunas situaciones generales en las que, sin importar el tamaño o giro de negocio, se enfrentan ' +
      'por igual y pueden prevenirse con un buen seguro.</p>' +
      '<p><b>Póliza de dueño de la empresa</b></p>' +
      '<p>Este tipo de seguro brinda cobertura de responsabilidad civil en caso de que un miembro o un ' +
      'producto de tu negocio causen daños a terceros.</p>' +
      '<p>También protege en caso de suspensión de actividades, la cobertura aplica sobre la pérdida real ' +
      'que haya tenido el negocio a causa de la interrupción de actividades.</p>' +
      '<p>Aunque no existe una póliza que cubra todas las necesidades de cada negocio, las agencias de ' +
      'seguros cuentan con muchos productos que son personalizables y pueden ayudarte.</p>' +
      '<p><b>Seguro de daños a la propiedad</b></p>' +
      '<p>Se refiere a asegurar el equipo, inventario, espacio de trabajo, mueblería y demás mobiliario ' +
      'de la oficina. Esto te ayudará en caso de un siniestro o accidente dentro de la empresa, dejándote ' +
      'amparado para cubrir los daños.</p>' +
      '<p>Informáte con tu asesor sobre la cobertura de sus pólizas. Existen desastres derivados de la ' +
      'naturaleza, tales como una inundación causada por un huracán o un terremoto, cuyos daños causados ' +
      'no suelen ser cubiertos por una póliza estándar. De igual manera, los seguros de daños a propiedad ' +
      'no cubren daños por el deterioro (uso) de la misma propiedad. Asegúrate que tu póliza cubra pérdidas '+
      'fuera de la propiedad, principalmente si sueles desplazarte con frecuencia con objetos/ productos de valor.</p>' +
      '<p><b>Seguro de gastos médicos</b></p>' +
      '<p>Aunque el tipo de trabajo que desarrollen los empleados sea relativamente de bajo esfuerzo físico, ' +
      'no contar con este seguro puede resultar en una demanda muy costosa en caso de no tener cobertura y sufrir ' +
      'algún accidente en horario de trabajo, ya sea fuera o dentro de las instalaciones.</p>' +
      '<p><b>Seguro de  auto</b></p>' +
      '<p>Si cuentas con empleados que deben conducir para trasladar productos o ir con clientes, te invitamos ' +
      'a considerar contratar un seguro de auto que cubra (por lo menos) daños a terceros, al empleado que lo ' +
      'conduce y que además proteja la unidad automotora. Si el empleado utiliza su propio auto con fines ' +
      'laborales, éste también deberá estar asegurada.</p>' +
      '<p><b>Seguro de vida</b></p>' +
      '<p>Algunas aseguradoras incluyen esta prestación bajo un esquema de descuentos nómina, el cual permite a ' +
      'las empresas tener un seguro de vida para sus empleados sin desembolsar grandes cantidades de dinero. ' +
      'Este seguro consiste en brindar una suma de dinero al familiar designando por empleado, en caso de ' +
      'fallecimiento.</p>' +
      '<p>No olvides que un seguro para tu negocio siempre será presupuestable, un evento catastrófico sin seguro nunca lo será.</p>';
    } else if (a==6) {
      this.title = '10 preguntas antes de comprar un seguro de auto';
      this.subtitles = '<p>Para muchos, el adquirir un auto es un motivo de orgullo, pero suelen olvidar la ' +
      'responsabilidad y prevención que esto conlleva.</p>' +
      '<p>La posesión de un auto debería ser motivo de orgullo, pero también de responsabilidad y prevención. Por ello te ' +
      'contamos todo lo que debes preguntarte antes de adquirir un seguro para tu auto.</p>' +
      '<p><b>¿Por qué quiero una póliza de seguro?</b></p>' +
      '<p>Es importante definir cuáles son las razones que te motivan a adquirir este tipo de póliza de seguro ' +
      'para determinar qué protección es la que necesitas. Es decir, podrías sólo buscar cumplir un requisito ' +
      'vial, proteger tu auto de cualquier daño que pueda sufrir o el que tu auto pueda ser robado.</p>' +
      '<p><b>¿Cuáles son las principales coberturas que hay en el mercado?</b></p>' +
      '<p>Pese a existir variantes en los tipos de coberturas, hay tres básicas: Responsabilidad Civil, ' +
      'Limitada y Amplia.</p>' +
      '<p>Un Seguro de Responsabilidad Civil (RC) únicamente cubre los daños ocasionados a terceros a partir de un ' +
      'siniestro donde tu auto fue el causante. Aunque está limitado en los montos a cubrir. La cobertura ' +
      'Limitada lleva implícita la de RC, además de asistencia legal y vial que dan al usuario mayor protección. ' +
      'Además contempla la protección en caso de robo total.</p>' +
      '<p>La cobertura cubre los daños a terceros, en caso de robo total y los daños materiales que sufra tu unidad, ' +
      'además de todo lo que cubren las dos coberturas anteriores.</p>' +
      '<p><b>¿Cómo puedo saber qué me ofrece cada compañía de seguros?</b></p>' +
      '<p>Una vez que sabes el tipo de cobertura que deseas contratar, deberás empezar a comparar. Contacta un agente de ' +
      'seguros, pregunta a tus conocidos sobre sus pólizas o ingresa a la página web de cada aseguradora.</p>' +
      '<p><b>¿Qué calificación tienen las aseguradoras ante Condusef?</b></p>' +
      '<p>Recuerda que contratar los servicios de una aseguradora es dejar en sus manos la protección y asistencia ' +
      'ante un percance. De ahí que la misma Condusef ha hecho distintos estudios para medir la eficiencia ante los ' +
      'usuarios. En la estadística oficial se puede observar el índice de reclamaciones y multas, hasta las ' +
      'calificaciones a los ajustadores.</p>' +
      '<p><b>¿Cuál es la suma asegurada en daños a terceros?</b></p>' +
      '<p>Es uno de los elementos principales que debes checar en cualquier póliza. Se refiere al monto total ' +
      'que la aseguradora está dispuesta a cubrir en caso de un siniestro.</p>' +
      '<p><b>¿Qué tipo de asistencia vial ofrecen?</b></p>' + 
      '<p>Uno de los aspectos más importante a considerar es la asistencia vial. Algunas pólizas limitan el número ' +
      'de eventos, como es el caso de grúas. Incluso otras sólo cubren hasta cierta distancia alrededor del ' +
      'domicilio registrado.</p>' +
      '<p><b>¿Cuáles son los deducibles que establece el contrato?</b></p>' + 
      '<p>Uno de los elementos para encontrar un seguro de auto a bajo precio es tener un deducible alto, sin ' +
      'embargo, puede no ser lo mejor. El deducible es la cantidad no cubierta por la aseguradora, es decir, lo ' +
      'que deberás cubrir de tu bolsa. Por ejemplo, en caso de robo total, es posible que la compañía ' +
      'establezca un deducible del 10%, lo que significa que deberás cubrir esa parte en efectivo para hacerte ' +
      'acreedor a la indemnización.</p>' +
      '<p>La recomendación es que revises y compares los porcentajes requeridos por las compañías y tomes ' +
      'lo que más te convenga. Recuerda que el robo total y daños a terceros son las principales circunstancias ' +
      'donde estás obligado a pagar un deducible.</p>' +
      '<p><b>¿Qué tipo de exclusiones tiene?</b></p>' +
      '<p>Este apartado debe ser muy claro antes de contratar, pues brinda certeza al propietario de las ' +
      'condiciones y alcances de su póliza. Aunque siempre hay restricciones, es obligación de las compañías ' +
      'manifestarlas, y del usuario, indagar sobre ellas.</p>'+
      '<p>Esperamos haberte ayudado para adquirir el seguro de auto ideal para ti. ¡Suerte! </p>';
    } else if (a==7) {
      this.title = 'Tips para elegir tu seguro de gastos médicos mayores';
      this.subtitles = '<p>En México existen una gran variedad de seguros de gastos médicos mayores lo ' +
      'que complica elegir la que mejor se adapte a nuestras necesidades. Si a esto le añadimos lo complejo ' +
      'de algunos conceptos, esto puede dificultar encontrar la opción ideal para cada uno.</p>' +
      '<p>Pero no te preocupes, por eso te hemos preparado un video con los 10 consejos para elegir tu ' +
      'seguro de gastos médicos mayores en México.</p>' +
      '<p><b>Define tu presupuesto</b></p>' +
      '<p>Antes de empezar a buscar opciones de seguro de gastos médicos, define el capital que puedes ' +
      'asignar al mes para ese fin. No olvides que, el seguro de gastos médicos debe de entrar en los ' +
      'costos fijos ya que es una necesidad básica. Una vez definido el presupuesto cotiza y compara los ' +
      'productos que te ofrece cada aseguradora.</p>' +
      '<p><b>Investiga los hospitales cubiertos dentro de la póliza</b></p>' +
      '<p>Este es un punto muy importante que muchos olvidan, ya que puede ocurrir  '+
      'que ninguno de los centros hospitalarios de tu cobertura se encuentren cerca de tu ' +
      'domicilio o, simplemente, no te agrada la calidad del mismo. Recuerda que el acudir a ' +
      'hospitales diferentes a las de tu red te dará una penalización aumentando el porcentaje de tu coaseguro.</p>' +
      '<p><b>Deducible y el tope de coaseguro</b></p>' +
      '<p>Lo ideal, es que el deducible no sea tan elevado o que no represente un problema financiero para ti ' +
      'en caso de enfermarte. De igual manera, evita un coaseguro ilimitado.</p>' +
      '<p><b>Tiempos de espera:</b></p>' +
      '<p>Pueden variar de una aseguradora a otra. Es muy importante que sepas bien las enfermedades que estarán ' +
      'cubiertas desde un inicio y aquellas que se cubren después de cierto periodo de tiempo.</p>' +
      '<p><b>Pago Directo, reembolsos y asesor</b></p>' +
      '<p>Revisa la experiencia de la aseguradora y pregunta a conocidos sobre su experiencia con la empresa. De ' +
      'igual manera investiga los tiempos de espera en el reembolso y haz todas las preguntas necesarias para ' +
      'resolver tus dudas a tu asesor.</p>' +
      '<p>Ahora sí estás listo para buscar el seguro de gastos médicos ideal para ti.</p>';
    }
    this.showInformation = true;
    /* this._api.get_subcards(param.id).then(response=>{
      if(response){
        this.subtitles = response;
      }
    }).catch(error=>{
      console.log(error);
    }) 
    this._api.get_comments(param.id).then(response=>{
      if(response){
        this.comments = response;
      }
    }).catch(error=>{
      console.log(error);
    })*/
  }

  saveComment(){
    this.comment['author'] = 'Usuario';
    this.comment['priority'] = 0;
    this._api.save_comments(this.title['id'], this.comment).then(responses=>{
      this.comment['text'] = '';
      this._api.get_comments(this.title['id']).then(response=>{
        if(response){
          this.comments = response;
        }
      }).catch(error=>{
        console.log(error);
      })
    }).catch(error=>{
      console.log(error);
    })
  }
  
  return(){
    this.showInformation = false;
  }

}
