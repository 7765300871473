import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { RestApiProvider } from '../provider/rest-api';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {

  showInformation = false;
  showError = false;
  cards:any = [];

  title = {};
  comment = {};
  subtitles:any = [];
  comments:any = [];

  constructor(
    public _api: RestApiProvider
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.goToAdmin();
    this.getCards();
  }

  goToAdmin(){
    this._api.login('superuser_gpi', 'Gpisuperuser2021').then(response=>{
      if(response['token_jwt']){
        this.getCards();
      }
      if(response['error']){
      }
    }).catch(error=>{
      console.log(error);
    })
  };

  getCards(){
    this._api.get_cards('Agentes%20de%20Seguros').then(response=>{
      if(response){
        for (let i = 0; i < response['length']; i++) {
          if(response[i]['category'] == 'Agentes de Seguros'){
            this.cards.push(response[i]);
          }
        }
      }
    }).catch(error=>{
      console.log(error);
    })
  };

  goToInformation(a){
    window.scrollTo(0, 0);
    if (a==1) {
      this.title = 'Cómo ser un asesor de seguros exitoso';
      this.subtitles = '<p>  Ser un asesor de seguros exitoso requiere no sólo muchas habilidades como ' +
      'gran capacidad verbal, buena imagen, excelente trato y un carácter emprendedor, sino también '+ 
      'poner en práctica algunas estrategias para conectar mejor con los clientes y aumentar así las ventas. '+
      'En este post te damos cinco consejos que te ayudarán a lograrlo. </p>' +
      '<dl>' +
        '<dt>1. Conoce a tu comprador ideal </dt>' +
        '<dd>Es importante que definas a tu buyer persona, es decir, el público ideal a quien estará dirigido ' +
        ' el seguro. A diferencia del target o público meta, el buyer persona es un concepto mucho menos abstracto, ' +
        ' ya que incopora más información sobre el comprador, como su edad, sexo, nivel de estudios, si tiene ' +
        'hijos o no, zona geográfica, intereses, aficiones, comportamientos, prejuicios etcétera. Tener claridad '+
        'sobre esto, te ayudará a segmentar y dirigir mejor las estrategias de publicidad y de comunicación.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>2. Escucha a tus clientes </dt>' +
        '<dd>Es muy común iniciar el proceso de venta, comunicando a los clientes potenciales todas las características y ' +
        ' beneficios de las pólizas o planes que ofreces. Sin embargo, una mejor estrategia consiste en escuchar a los ' +
        'clientes para saber qué es lo que están buscando exactamente y cómo podrías darles algo que realmente los ' +
        'beneficie. Infórmate más sobre su estilo de vida, sus preocupaciones o su familia. Más que vendedor, ' +
        'enfócate en ser un asesor de seguros.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>3. Conviérte en un experto en todo lo relacionado con tu producto </dt>' +
        '<dd>Un factor esencial para cerrar una venta es demostrar que conoces a fondo tu negocio, pero no sólo se trata ' +
        'del seguro mismo sino también de algunos aspectos esenciales (y a veces no tan esenciales) que lo rodean. ' +
        'Demostrar que sabes exactamente lo que estás vendiendo dará a tus clientes la confianza que necesitan para ' +
        'decidirse; en cambio, dudar o saber poco, podría hacerte perder una venta.</dd>' +
      '</dl>' +
      '<dl>' + 
        '<dt>4. Haz que tus clientes te recomienden </dt>' +
        '<dd>El buen servicio debe abarcar todos los procesos en los que interactúas con los clientes, y no sólo al ' +
        'momento de vender la póliza de seguros. Esto es particularmente importante cuando se encuentren en algún ' +
        'siniestro, pues de esta manera se fortalecerá su fidelización, favorecerá la venta cruzada de pólizas y ' +
        'propiciarás que te recomienden con otros clientes potenciales.</dd>' +
      '</dl>' +
      '<dl>' + 
        '<dt>5. Conoce tus puntos débiles </dt>' +
        '<dd>Lo más normal es que quieras olvidar las ventas que perdiste y enfocarte en las que sí has logrado. ' +
        'Sin embargo, es importante que analices tanto tus fortalezas y objetivos logrados como tus puntos más débiles, ' +
        'de esta forma sacarás lo mejor de estas experiencias. Te recomendamos tomarte unos momentos para hacer una lista ' +
        'de todo aquello que crees que pudiste haber hecho de manera diferente, y tenerlo en cuenta para la siguiente vez ' +
        'que te encuentres en la misma situación. Esto te ayudará a estar mejor preparado y aumentar tu confianza.</dd>' +
      '</dl>' ;
    } else if (a==2) {
      this.title = '¿C&oacute;mo transmitir credibilidad y confianza a la hora de vender una p&oacute;liza de seguros?';
      this.subtitles = '<p> Vender p&oacute;lizas de seguro no es cosa sencilla, ya que es uno de los servicios m&aacute;s caros que se pueden ' +
      'adquirir en beneficio de su seguridad. Vender un seguro, sin importar cu&aacute;l sea, es vender confianza, seguridad y tranquilidad.</p>' +
      '<p> Por ello, buscamos ayudarte a tener &eacute;xito a la hora en que prospectes al cliente.</p>' +
      '<dl>' +
        '<dt>Profesionalismo ante todo </dt>' +
        '<dd>Las personas est&aacute;n contratando un servicio para su futuro, para estar m&aacute;s tranquilos por el mañana. Por ello, te otorgan parte de su patrimonio' +
        'al momento de firmar una p&oacute;liza de seguros, y por ello, es vital que transmitas profesionalismo y seguridad. </dd>' +
        '<dd> Nadie dice que sea obligatorio usar tacones o un traje muy caro, pero sí lo es la limpieza e higiene en tu persona y vestimenta, as&iacute; como tambi&eacute;n evitar colores '+
        'demasiado llamativos (azul, gris y negro son los colores que transmiten mayor seriedad y confianza). Recuerda que menos es m&aacute;s, as&iacute; que opta por prendas de corte '+
        'sencillo, joyer&iacute;a poco llamativa y un peinado simple.  </dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Habla con propiedad</dt>' +
        '<dd>No quiere decir que hablemos con lenguaje rebuscado y demasiado formal, lo ideal es sonar ' +
        'amable sin caer en el exceso de confianza. Si lo que se quiere es vender una póliza de seguros, '+
        'debemos no solo ser conocedores del tema, sino también, saber exponerlo con sutileza y argumentos. </dd>' +        
      '</dl>' +
      '<dl>' +
        '<dt>Conoce a tu cliente</dt>' +
        '<dd>Conocer a tu cliente te ayudará a saber qué póliza de seguros ofrecerle y cuáles descartar,'+
        'además de que te hará sentirte más cómodo con él en el momento de asesorarlo. </dd>' +        
      '</dl>' +
      '<dl>' +
        '<dt>Recuerda tu aseguradora</dt>' +
        '<dd>Recuérdale a tus prospectos que no van a comprarte sólo a tí. Por ejemplo, “hace 8 meses que estoy trabajando'+
        'con la agencia de seguros y soy muy afortunado porque nuestra oficina tiene más de 45 años de experiencia en seguros. '+
        'De hecho, cada póliza de seguros es doblemente revisada”.</dd>' +        
      '</dl>' +
      '<dl>' +
        '<dt>Conoce perfectamente tus productos</dt>' +
        '<dd>Es de vital importancia conocer todos los aspectos de los servicios que vienen incluidos en las pólizas de seguros.'+
        ' Si no los conoces a fondo puedes cometer un error al momento de prospectar o dar información errónea que puede ocasionar malos entendidos. </dd>' +        
      '</dl>' +
      '<dl>' +
        '<dt>Brinda excelente atención a tus clientes</dt>' +
        '<dd>Nadie te pide que trabajes 24/7 pero sí es importante que tus clientes se sientan atendidos por ti en horario regular'+
        ' en días laborables. Cumple siempre con los horarios y fechas que determinas con ellos para las citas así como con el envío de documentos. </dd>' +
        '<dd>Sé sincero, respetuoso y transparente con tus clientes. Si consigues entablar una relación de confianza, la venta puntual se convertirá en algo a largo plazo. ¡Mucho éxito! </dd>'
      '</dl>';
    } else if (a==3) {
      this.title = '¿Qué puedo hacer para impulsar el crecimiento de mi agencia de seguros?';
      this.subtitles = '<p>Aunque no lo creas, la principal clave del éxito en tu agencia de seguros son los buenos procesos, que sean el resultado de una buena gestión '+
      'empresarial, una excelente atención al cliente así como una clara estrategia de ventas  y de marketing clara que considere el crecimiento empresarial. </p> ' +
      '<p>Por ello, nos hemos dado a la tarea de reunir los aspectos más importantes que debes cubrir en tus procesos</p>'+
      '<dl>'+
        '<dt>Ventas</dt>' +
        '<dd>'+
          '<ul> '+
            '<li>Ayuda a tus vendedores a identificar las necesidades de cada cliente potencial.'+
            '</li>'+
            '<li>Crea material eficiente para tus vendedores: hojas de trabajo, presentaciones, videos u otros materiales de apoyo.'+
            '</li>'+
            '<li>Analiza a la competencia y busca aspectos que te ayuden a destacar de las demás aseguradoras.'+
            '</li>'+
            '<li>Ayuda a tus vendedores a conocer cada uno de los productos que ofrece la aseguradora.'+
            '</li>'+
            '<li>Considera evaluaciones periódicas de desempeño así como capacitaciones constantes de tus empleados.'+
            '</li>'+
          '</ul>'+
        '</dd>'+
      '</dl>'+
      '<dl>' +
        '<dt>Proceso de nuevos clientes</dt>' +
        '<dd>El propósito de un Proceso de Nuevo Cliente es llevar a los nuevos clientes de ser un comprador de '+
        'seguros a sentirse como un miembro de su familia  de manera efectiva.</dd>' +
        '<dd>Tomar el control sobre la experiencia de tus clientes durante el primer año puede significar la diferencia '+
        'entre perder un cliente o ganar toda una vida de renovaciones de pólizas de seguros, además de ahorrar el tiempo '+
        'que toma captar un nuevo cliente. La principal pregunta que deben resolver tus acciones es: ¿Qué ha realizado la '+
        'competencia para fidelizar a sus clientes?</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Referidos</dt>' +
        '<dd>Este proceso es de gran ayuda para prospectar nuevos clientes de manera efectiva, ya que son tus mismos '+
        'clientes los que te remiten con clientes potenciales con posible interés en tu producto. Pero, ¿por qué mis '+
        'clientes me ayudarían a captar a sus conocidos? Muy sencillo, obtienen un beneficio en tu Plan de referidos. '+
        'Y a continuación, te damos una lista de los aspectos que debe cubrir dicha estrategia.</dd>' +
        '<dd>'+
          '<ul> '+
            '<li>Considera crear un plan de recompensas para tus clientes.'+
            '</li>'+
            '<li>Crea una estrategia clara y sencilla y establece los procedimientos adecuados para implementarla.'+
            '</li>'+
            '<li>Capacita a tus aseguradores y proporciona el material necesario para que puedan comunicárselo a sus clientes de manera efectiva.'+
            '</li>'+
            '<li>Considera las redes sociales para informar sobre este proyecto.'+
            '</li>'+
            '<li>Envía de manera constante información sobre este Plan a tus clientes.'+
            '</li>'+
          '</ul>'+
        '</dd>'+
      '</dl>'+
      '<dl>' +
        '<dt>Revisión de Pólizas de Seguros</dt>' +
        '<dd>Antes de renovar una póliza de seguros, es importante que tus clientes tengan la posibilidad de contactar '+
        'a tu empresa para una revisión anual de su cobertura. El propósito de esto es identificar nuevas oportunidades '+
        'de ventas e impulsar la retención fortaleciendo las relaciones que tienes con tus clientes.</dd>'+
      '</dl>'+
      '<dl>' +
        '<dt>Marketing Digital</dt>' +
        '<dd>Los tiempos han cambiado y es vital cambiar con ellos.  Considera abrir cuentas en Redes Sociales para tu '+
        'agencia, además de tener una página web con toda la información de interés para tus clientes, expuesta de forma '+
        'clara y sencilla. </dd>'+
        '<dd>Lo ideal es contar con un equipo de Marketing para que te ayude a comunicar la información en los canales '+
        'adecuados además de realizar un plan de marketing adecuado. </dd>' +
      '</dl>'+
      '<dl>' +
        '<dt>Contratación de empleados</dt>' +
        '<dd>Es muy importante establecer procedimientos claros desde la publicación de la vacante, a la entrevista y hacer '+
        'una oferta.  Lo ideal es establecer un perfil de ventas que contemple las habilidades del vendedor ideal de nuestros productos</dd>'+
        '<dd>Encontrar, contratar y mantener a los mejores empleados es una de las habilidades más importantes para tener un '+
        'agente de seguros exitoso. Para lograrlo, es importante establecer estrategias de venta claras y realistas además de la capacitación constante de tu equipo. </dd>' +
      '</dl>' +
      '<p>¿Qué medidas ya has implementado y cuáles crees que puedes mejorar en tu negocio? </p>'+
      '<p> Cuéntanos.</p>';
    } else if (a==4) {
      this.title = '¿Qué aspectos debemos destacar de las pólizas de seguros a vender?';
      this.subtitles = '<p> La venta de seguros es diferente de la venta de cualquier otro servicio o producto, '+
      'ya que, al no ser un bien tangible, suele ser un poco más complicado que las personas se animen a adquirirlo. '+
      'Vender seguros es ofrecer ideas, confianza y seguridad. </p>' +
      '<p>Esto ocasiona que muchos vendedores de seguros renuncien en los primeros dos años, por ello creamos este artículo, buscando ayudar a '+
      'todos aquellos agentes a prospectar clientes y fidelizarlos. Por ello, nos hemos dado a la tarea de realizar este '+
      'artículo que te ayude a destacar la importancia y beneficios de los seguros, ya sea un seguro de vida o de gastos '+
      'médicos. </p>'+
      '<dl>'+
        '<dt>Busca el momento adecuado </dt>' +
        '<dd>Aquellos clientes potenciales que se encuentran en las siguientes situaciones, es más posible que adquieran tus productos, siempre y cuando '+
        'les ofrezcas el tipo de seguro ideal para su situación actual:</dd>' +      
        '<dd>'+
          '<ul> '+
            '<li>Casarse o comprometerse'+
            '</li>'+
            '<li>Comprar un auto'+
            '</li>'+
            '<li>Tener un bebé'+
            '</li>'+
            '<li>Comprar una casa'+
            '</li>'+
            '<li>Cumplir más de 40 años'+
            '</li>'+
            '<li>Cambio de trabajo'+
            '</li>'+
          '</ul>'+
        '</dd>'+
      '</dl>' +
      '<dl>' +
      '<dt>No lo llames seguro</dt>' +
      '<dd>Suena raro que te digamos esto, pero al acercarte al cliente potencial lo ideal sería que lo llamarás protección '+
      'hipotecaria, fondo de ahorro para el retiro, etc. ¿Por qué? Las personas suelen reaccionar de forma reservada a la '+
      'hora de escuchar la palabra “seguro” .</dd>'+
      '<dd>También te sugerimos enfatizar lo que estaría asegurando en caso de interesarse en el producto. De esta manera, les '+
      'será más sencillo relacionarse con el tema. </dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Cuenta una historia</dt>'+
        '<dd>Cuéntale a los clientes potenciales alguna anécdota sobre una persona que necesitaba un seguro de gastos '+
        'médicos o una póliza de seguro para su auto. Mientras mejor cuentes la historia, más fácil será para tu cliente '+
        'potencial ver el valor de la cobertura.</dd>' +
        '<dd>¡Ojo!, nunca inventes una historia. </dd>'+
      '</dl>' +
      '<dl>' +
        '<dt>Habla sobre sus hijos</dt>' +
        '<dd>A todo el mundo le encanta hablar de sus hijos y también es una gran manera de crear una buena relación.<dd>' +
        '<dd>Pero lo que no siempre se considera es que cuanto más se habla con un cliente potencial acerca de sus hijos, '+
        'más recuerda sobre la importancia de proteger a su familia. Si hablas, en detalle, sobre todo lo relacionado con '+
        'los hijos de tu cliente potencial, ni siquiera tendrás que mencionar cómo el seguro de vida los afecta.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Crea una conversación a partir de supuestos </dt>'+
        '<dd>Habla con los clientes potenciales sobre el seguro de vida de la manera más natural posible, de su vida cotidiana '+
        'y de los pequeños conflictos que afectan su día a día. Esto te permitirá hablar sobre los diferentes tipos de seguro '+
        'que pueden proteger su patrimonio y su familia, además de darte una idea de las posibles coberturas que podrían '+
        'cubrir en sus pólizas de seguro.</dd>'+
      '</dl>' +
      '<dl>' +
        '<dt>Utiliza los costos a tu favor </dt>' +
        '<dd>Sé claro con las coberturas, deducibles y primas de seguro. Ya que son temas en los que el vendedor de seguros '+
        'no suele ahondar hasta cotizar el plan seguro. </dd>' +
        '<dd>Asegúrate de que tus clientes potenciales sepan cuán baratas pueden ser algunas pólizas. No olvides mencionar que, '+
        'mientras más joven contrate su seguro de vida, más barato será el costo del mismo.</dd>' +
        '<dd>Esperamos que este pequeño listado te ayude al momento de prospecta clientes y cerrar pólizas con '+
        'éxito. ¡Suerte! </dd>' +
      '</dl>';
    }  else if (a==5) {
      this.title = 'Beneficios de contar con un Software para Administrar Seguros';
      this.subtitles = '<p>Las compañías de seguros necesitan un sistema de gestión de proyectos, como un software de seguros, que '+
      'agilice y automatice tanto los trámites que llevan a cabo con los clientes como la parte interna de la empresa '+
      'enfocada en la gestión de informes y pólizas.</p>'+
      '<p>El software de seguros es un tipo de solución de gestión empresarial que cuenta con las herramientas para '+
      'automatizar procesos y brindar la seguridad de la información necesarias para una óptima administración '+
      'de clientes, pólizas, recibos, expedientes, siniestros y todos los procesos propios del sector seguros. '+
      '¡Una excelente forma de optimizar recursos! </p>' +
      '<p> Pero, ¿cuáles son los beneficios de contar con un software de gestión de seguros?</p>' +
      '<ul>' +
        '<li>Te permiten gestionar y administrar los clientes de manera eficiente.  Agrega nuevos ' +
        'clientes, mantén los existentes y elimina aquellos que cancelaron sus pólizas.' +
        '</li>' +
        '<li>Manejo de Ingresos y Comisiones</li>' +
        '<li>Se puede gestionar mejor la cartera de clientes. Incluso puede aumentar la '+
        'productividad laboral y crear clasificaciones como tipo de seguro, tipo de cliente y área geográfica.' +
        '</li>' +
        '<li>Mayor administración de las pólizas de seguro. Se pueden agregar las nuevas, ' +
        'monitorear los vencimientos o las próximas a vencer.' +
        '</li>' +
        '<li> Obtención de estadísticas. Este tipo de software permite generar las estadísticas necesarias para ' +
        'medir y evaluar el comportamiento del negocio, también facilita el seguimiento de las ventas por agente ' +
        'de seguro.' +
        '</li>' +
        '<li>Mayor seguimiento y control de cada uno de los procesos de ventas. </li>' +
        '<li>Planificación del calendario. Puedes agendar reuniones, citas, monitorear vencimientos, asignar '+
        'tareas y, hasta, el cumpleaños de tus clientes.' +
        '</li>' +
        '<li>Manejo de documentos. Permite generar las cuentas por cobrar y contratos de las pólizas, '+
        'pudiendo ser enviados por email.' +
        '</li>' +
        '<li>Optimización de recursos al contar con disponibilidad de la información, casi de manera inmediata.</li>' +
      '</ul>' +
      '<p>¿Cómo se refleja esto en tu agencia de seguros?</p>' +
      '<p>Si manejan el software adecuado, puedes tener una amplia ventaja competitiva además de lograr una reducción de '+
      'costos significativa. ¿Te animas a implementarlo en tu negocio?</p>';
    } else if (a==6) {
      this.title = 'Preguntas que debes hacerle a un cliente potencial';
      this.subtitles = '<p>La venta de seguros es una profesión que ofrece muchos beneficios: flexibilidad de horario, amplias '+
      'posibilidades de crecimiento profesional y comisiones elevadas, entre muchos otros. </p> '+
      '<p>Uno de los métodos más efectivos al que podemos recurrir es la venta consultiva, en la que el objetivo es escuchar '+
      'y conocer mejor a los clientes potenciales a través de preguntas para poder cubrir sus necesidades con nuestros '+
      'productos o servicios, en otras palabras ofrecerles soluciones más que productos. Además, esto nos permitirá '+
      'conocer mejor a nuestro público meta y diseñar mejores estrategias de venta.</p>' +
      '<p>Antes que nada, es importante cuidar el trato al cliente y de forma natural buscar conectar con nuestros '+
      'clientes potenciales es decir, interesarnos genuinamente por ellos y ganarnos su confianza. Sólo entonces será '+
      'conveniente hacerles algunas preguntas exploratorias que nos ayudarán a conocer mejor lo que espera de nuestro '+
      'producto o servicio, por ejemplo: </p>' +
      '<ul>' +
        '<li> ¿Cuales son las expectativas que tiene acerca de un producto o servicio como el que le ofrecemos?</li>' +
        '<li>¿Cuál es una de sus prioridades principales para este año? ¿Por qué?</li>' +
        '<li>¿Ha contratado seguros con anterioridad? ¿Qué fue lo que captó su atención de esas compañías?</li>' +
        '<li>¿Cuenta con un seguro actualmente?, ¿qué podría hacer que cambiara de aseguradora?</li>' +
        '<li>¿Qué le gustaría conocer acerca de nuestra empresa?</li>' +
        '<li>¿Qué esperaría después de adquirir nuestro producto o servicio?</li>' +
        '<li>¿Cuál sería el mejor momento para volver a comunicarnos con él?</li>' +
      '</ul>' + 
      '<p>Cuantas más preguntas hagamos, más probable será que encontremos el producto apropiado para nuestro cliente ' +
      'potencial. Te recomendamos ir anotando las preguntas que consideres más eficaces o que te hayan ayudado a ' +
      'entender mejor a tus clientes, y de este modo crear tu cuestionario de ventas.</p>' +
      '<p>Por último, debemos recordar lo importante que es escuchar atentamente a nuestro posible cliente, ' +
      'concentrarnos en sus respuestas, mostrar interés por lo que dice, respetar las pausas que hace y evitar hacer ' +
      'interrupciones; todo esto nos ayudará enormemente a incrementar nuestras habilidades en ventas.</p>';
    } else if (a==7) {
      this.title = '¿Cómo y por qué digitalizar mi agencia de seguros?';
      this.subtitles = '<p> La nueva economía digital ha obligado a todos los sectores de las diversas industrias a ' +
      'modernizarse, y es que en estos tiempos tecnológicos, no sumarse a estas transformaciones significa desaparecer.</p>'+
      '<p>Y aunque no lo creas, las aseguradoras pueden beneficiarse de manera significativa gracias a la transformación ' +
      'digital.  Desde la automatización de procesos a la aplicación de analítica para detección de fraude, la nueva ' +
      'economía digital agiliza las gestiones tanto para las compañías como para sus usuarios.</p>' +
      '<p>Hay varias maneras en las que se pueden integrar las herramientas digitales en tu agencia de seguros, tanto ' +
      'en la parte de prevención, la de la primera notificación del incidente o siniestro, la automatización de la ' +
      'gestión de las reclamaciones, evaluación de daños y reparación y agilización de los arreglos. Cada una de ' +
      'estas tiene varias herramientas digitales propias que se pueden aprovechar, tanto para beneficio de tus ' +
      'clientes como de tu propia empresa. Todo esto con la finalidad de simplificar la comunicación de los asegurados ' +
      'con sus respectivas agencias de seguro.</p>' +
      '<p>Por ejemplo, es posible presentar notificaciones automatizadas así como desarrollar herramientas intuitivas ' +
      'digitales tanto para tus clientes como para tus agentes de seguros. Una vez notificado el incidente, las ' +
      'aseguradoras pueden emplear distintos mecanismos para analizar si se trata de una reclamación real o es un ' +
      'caso de fraude. ¿Cómo? Muy sencillo, aquí juegan un papel importante las herramientas de analítica, con ' +
      'sistemas de gestión y detección que estudian cientos de casos para detectar posibles intentos de engañar a ' +
      'las aseguradoras. De igual manera,  es posible dar seguimiento en tiempo real de la respuesta al siniestro ' +
      'o incidente.</p>' +
      '<p>También pueden verse beneficiadas la parte de evaluación de daños y reparación de los mismos gracias a ' +
      'este tipo de tecnologías, ya que es posible seleccionar y gestionar las fecha de tasación y de pagos. Una ' +
      'vez ya terminado el proceso con nuestros clientes, puedes realizar los pagos, verificación de facturas o ' +
      'dar seguimientos a los ajustes de piezas dañadas.</p>' +
      '<p>Como te darás cuenta, realizar la transición digital de tu agencia de seguros puede ser muy beneficiosa ' +
      'tanto para ti como tus clientes, agilizando los procesos y mejorando la atención al cliente, aspectos ' +
      'vitales en la industria.¿Te atreves a sumarte a la digitalización de tu empresa?</p>';
    } else if (a==8) {
      this.title = 'Cómo captar clientes potenciales';
      this.subtitles = '<p>Debemos de entender, desde un principio, que la venta de seguros es diferente de la ' +
      'venta de cualquier otro tipo de servicio o producto debido a que un seguro de vida es un compromiso a ' +
      'largo plazo que no es tangible. Al vender un seguro, ofreces seguridad, confianza y tranquilidad para el ' +
      'futuro.</p>' +
      '<p>Por ello, las ventas de seguros se han convertido en una actividad muy compleja. El mercado se encuentra ' +
      'en constante innovación y ha minimizado los márgenes de utilidad; además de que la competencia se ha vuelto ' +
      'cada vez más agresiva dificultando los procesos de venta.</p>' +
      '<p>Por ello, esperamos que estos tips te ayuden a captar mayor número de clientes potenciales y cierres tus ' +
      'pólizas de manera exitosa.</p>' +
      '<dl>' +
        '<dt>Analiza a tu prospecto</dt>' +
        '<dd>Antes de ir a la cita con tu cliente potencial, estúdialo un poco: su edad, a que se dedica, sus ' +
        'pasatiempos, si está casado o tiene hijos, etc. Esto te ayudará a presentar los productos más ideales ' +
        'para él de acuerdo a sus necesidades, lo que te dará una ventaja competitiva a la hora de presentar tus ' +
        'productos.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Aprende a preguntar y escuchar</dt>' +
        '<dd>No es necesario que hagas un listado de preguntas y lo bombardees con ellas. Antes de ir directo a la ' +
        'presentación de tus productos, inicia un conversación y ve realizando pequeñas preguntas sobre la marcha, ' +
        'esto te ayudará a detectar las necesidades de tu posible cliente. Escúchalo de forma activa, guarda silencio ' +
        'y realiza siempre contacto visual al hablar.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Simplifica su vida</dt>' +
        '<dd>Si no resaltas el valor que tu producto ofrece a tu posible cliente, es posible que pierdas su ' +
        'interés. Si quieres captar a tu cliente, ayúdalo, no le vendas. Ofrécele una solución a sus necesidades ' +
        'y problemas.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Sé honesto</dt>' +
        '<dd>Si no eres la mejor opción, no pretendas serlo. Si eres sincero puedes generar una relación de ' +
        'negocios a largo plazo. Explícale a detalle las ventajas de tus productos y aclara todas las dudas ' +
        'que vayan surgiendo de forma clara y directa.</dd>' +
      '</dl>' + 
      '<dl>' +
        '<dt>Cierra la venta</dt>' +
        '<dd>Superadas las objeciones, no esperes más: cierra la venta. Envía toda la documentación en las ' +
        'fechas de entrega establecidas, mantente en contacto con tu prospecto y verifica que todos los documentos ' +
        'solicitados sean los requeridos. También determina claramente los plazos de pago, las primas y resuelve ' +
        'todas las dudas de tu cliente.</dd>' +
      '</dl>' +
      '<p>¡Mucho éxito! </p>';
    } else if (a==9) {
      this.title = '¿Cómo ser un Agente de Seguros exitoso?';
      this.subtitles = '<p> Como ya lo hemos dicho, ser un agente de seguros exitoso no es tarea fácil. Involucra ' +
      'constancia, conocimiento y esfuerzo.</p>' +
      '<p>Por ello, nos dimos a la tarea de enumerar una serie de cualidades que todo agente de seguros que busque ' +
      'el éxito debe tener para poder escalar en su carrera y obtener todos los beneficios profesionales, ' +
      'personales y económicos que la profesión puede ofrecer.</p>' +
      '<dl>' +
        '<dt>Constancia</dt>' +
        '<dd>Ser constante es determinante para ser un agente exitoso y con una amplia cartera de clientes. ' +
        'Lo ideal es crear una rutina con cada cliente y no saltarte ningún paso para conseguir una venta ' +
        'exitosa.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Persistencia</dt>' +
        '<dd>Debes de estar muy consciente que, a lo largo de tu carrera, recibirás muchas negativas, ' +
        'incluso a última hora. ¡No te desanimes! Enfócate en conseguir clientes constantes y responsables ' +
        'de sus pólizas, esto te ayudará a ir acumulando de poco a poco un buen número de pólizas.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Perseverancia</dt>' +
        '<dd>La perseverancia es muy importante, ya que para poder progresar es necesario que te pongas ' +
        'metas cada vez más altas y te esfuerces en cumplirlas a pesar de no rendir cuentas a un jefe directo. ' +
        'Debes ser exigente contigo mismo.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Pro-actividad</dt>' +
        '<dd>Busca clientes constantemente, no te confíes aunque tus ingresos hayan llegado a un nivel ' +
        'satisfactorio. Esto te ayudará a que, si un cliente cancela su póliza, no te afecte duramente ' +
        'en tus bonos. Además esto también te ayudará a asegurar tu estabilidad y crecimiento constante.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt> Creatividad</dt>' +
        '<dd>Innova constantemente tus técnicas de venta y fuentes de prospección, y sé creativo a la ' +
        'hora de buscar mayores oportunidades para ampliar tu red de clientes. Esto será el principal ' +
        'diferenciador entre otros agentes de seguros y tú.</dd>' +
        '<dd>buscar las oportunidades donde otros no las ven.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Administra tu tiempo</dt>' +
        '<dd>Comienza a administrar tu tiempo de forma óptima y responsable. Sabemos que ser ' +
        'autónomo puede fomentar las distracciones, el ocio y los imprevistos. La distribución ideal ' +
        'de tiempo de un agente de seguros es invertir el 80% con sus clientes y el 20% en labores ' +
        'administrativas de rutina.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Lleva una agenda</dt>' +
        '<dd>Llevar un registro de tus actividades debe ser un hábito: cuando visitaste a tal cliente, ' +
        'si se concretó o no la venta, en cuántas llamadas se concretó y hacer estadísticas globales de tu  ' +
        'desempeño semanal, mensual y anual para autoevaluarse constantemente y buscar áreas de oportunidad en ' +
        'base a estos datos.</dd>' +
        '<dd>Ser agente de seguros es una profesión que requiere de paciencia y perseverancia, no todos poseen ' +
        'estas cualidades o están dispuestos a desarrollarlas, pero aquellos que sí lo hacen pueden a llegar a ' +
        'tener un gran éxito en esta carrera.</dd>'
      '</dl>';
    } else if (a==10) {
      this.title = 'Tips para vender una póliza de seguros de Gastos Médicos';
      this.subtitles = '<p> Sin duda, una de las mejores y más importantes decisiones de vida que puede tomar ' +
      'una persona, es la de adquirir una póliza de seguros de gastos médicos. </p>' +
      '<p>El problema principal es que, al haber en nuestro país poca cultura de prevención así como el desconocimiento ' +
      'de todas las ventajas de contar con una póliza de gastos médicos, ocasionan que pocas personas se interesen en ' +
      'adquirir uno.</p>' +
      '<p>Por ello, nos dimos a la tarea de hacer un listado de consejos para que, como agente de seguros, puedas ' +
      'aumentar tus ventas de seguros de gastos médicos.</p>' +
      '<dl> '+
        '<dt>Comienza prospectando con tus conocidos<dt>' +
        '<dd>Este es el principal recurso de un agente de seguros para empezar a prospectar. Familiares, amigos, conocidos, ' +
        'compañeros de trabajo, ex compañeros de clase, ¡todo se vale!</dd>' +
        '<dd>Es la actividad más recomendable para iniciar tu cartera de clientes, ya que lo más probable es que ya conozcas ' +
        'las preocupaciones y necesidades concretas de tus conocidos en temas de salud.</dd>' +
        '<dd>También puede ocurrir que tus conocidos no sean quienes necesiten el seguro de gastos médicos, pero puedes ' +
        'preguntarles sobre si conocen a alguien que pueda necesitarlo: algún amigo, vecino, conocido, etc. Esto te ' +
        'ayudará a ir creando una red de contactos que te será de utilidad.</dd>' +
      '</dl>' +
      '<dl> '+
        '<dt>Tener tacto al hablar de los beneficios del producto<dt>' +
        '<dd>Es muy importante que al ofrecer el producto hagas la aclaración de que no cubre padecimientos anteriores ' +
        'a la contratación de la póliza.</dd>' +
        '<dd>Además, hay que tener especial cuidado al hablar sobre los padecimientos de salud de tus prospectos, ya que ' +
        'cuando una persona comparte información sobre este tipo de problemas puede ponerse sensible.</dd>' +
        '<dd>En caso de que por su situación actual no sea candidato, explícale amablemente por qué no puede aplicar ' +
        'para adquirir un seguro de este tipo sin herir su sensibilidad, recuerda que aunque no sea candidata puede ' +
        'recomendarte con su familia y amigos.</dd>' +
      '</dl>' +
      '<dl> '+
        '<dt>Busca personas que sean más propensas a comprarte<dt>' +
        '<dd>Si lo analizas, hay personas más proclives a adquirir un seguro que otras, por ejemplo:</dd>' +
        '<dd>' +
        '<ul>' + 
          '<li>Personas que están esperando un bebé o ya planean tener uno.</li>' +
          '<li>Algún conocido con hijos que desee proteger la salud de los mismos.</li>' +
          '<li>Amigos deportistas.</li>' +
          '<li>Personas que viajan mucho.</li>' +
          '<li>Conocidos que desean atención particular.</li>' +
        '</ul>' +
        '</dd>' +
      '</dl>' +
      '<dl> '+
        '<dt>Resalta las ventajas más importantes del seguro <dt>' +
        '<dd>Es importante que des a conocer todos los beneficios de tu producto a tu prospecto ' +
        'para que tome una decisión informada. Resalta siempre algunos de los siguientes aspectos: </dd>' +
      '</dl>' +
      '<dd>' +
        '<ul>' + 
          '<li>La tranquilidad de que en momentos de crisis podrán recibir una atención del mejor ' +
          'nivel en hospitales particulares.</li>' +
          '<li>En el caso de mujeres embarazadas, con planes de o en edad fértil, menciona que ' +
          'cubre los gastos médicos del nacimiento del bebé.</li>' +
          '<li>El deducible, ósea, la cantidad fija que se pagará cada que se haga uso de la póliza ' +
          'de seguro, la cual puede ser alta o baja de acuerdo a la capacidad de pago de la persona que ' +
          'contrata el seguro.</li>' +
          '<li>En caso de sufrir accidentes, no se paga deducible.</li>' +
          '<li>Si le diagnostican una enfermedad grave o degenerativa, mientras la persona pague su póliza ' +
          'seguirá cubierto y podrá recibir toda la atención médica que necesite.</li>' +
        '</ul>' +
        '</dd>' +
        '<p>Esperamos que estos consejos te ayuden a prospectar y cerrar nuevas pólizas. ¡Suerte!</p>';
    } else if (a==11) {
      this.title = '5 cualidades de un agente de seguros exitoso';
      this.subtitles = '<p> Ser un agente de seguros exitoso no es tan fácil como parece, se necesita constancia, ' +
      'compromiso y creatividad, para lograr cerrar pólizas y tener clientes satisfechos.</p>' +
      '<p>Por ello, nos dimos a la tarea de enumerar las cualidades que todo agente de seguros debe tener ' +
      'para asegurar el éxito en el mundo de los seguros.</p>' +
      '<dl>' +
        '<dt>Seguimiento constante de los clientes</dt>' +
        '<dd>Las personas esperan que su agente de seguros se comprometa con ellos no sólo para cerrar la ' +
        'póliza sino para dar continuidad a la misma año con año, para resolver dudas o cambiar datos o el ' +
        'tipo de póliza según su situación personal o familiar.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Inspirar confianza</dt>' +
        '<dd>Para ganarte la confianza, es muy importante que tengas constante comunicación con tus ' +
        'clientes, además de tener la flexibilidad de reunirte con él en caso de ser necesarios. También ' +
        'es muy importante que siempre resuelvas todas las dudas y preguntas de tus clientes antes de cerrar ' +
        'la póliza, dando todas las veces información transparente, clara y precisa.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Conoce a tus clientes</dt>' +
        '<dd>Una variable muy importante es que el agente conozca a fondo todo lo posible sobre su prospecto. ' +
        'Pregunta de forma abierta y sencilla todo lo referente al contexto laboral, social, económico y familiar ' +
        'de su cliente, ya que de esta manera el asesoramiento será personalizado, como un traje hecho a la medida.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Sé profesional</dt>' +
        '<dd>Aquí debes considerar varios aspectos: la puntualidad, la presentación personal, el conocimiento del producto y, ' +
        'por supuesto, la honestidad para aceptar cuando no se conoce a fondo un aspecto y no se puede responder al ' +
        'instante una pregunta, solicitando tiempo para investigar más a fondo y dar información certera al cliente.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Piensa en el cliente</dt>' +
        '<dd>No antepongas tus intereses comerciales sobre las necesidades de tus prospectos, lo que te dará credibilidad ' +
        'y una posible relación comercial por varios años. </dd>' +
        '<dd>Trabaja en los puntos anteriores y te aseguramos que tus clientes quedarán satisfechos con tu desempeño. Además, ' +
        'no olvides que son posibles referencias con sus conocidos, lo que puede ayudarte a aumentar tu cartera de ' +
        'clientes.</dd>' +
      '</dl>';
    } else if (a==12) {
      this.title = 'Tips para conseguir referidos y vender más seguros';
      this.subtitles = '<p> Son muchos los agentes de seguros que no piden referidos a sus clientes por miedo a ' +
      'molestarlos o estar pidiendo “demasiado”. Lo que buscamos es que tus clientes promuevan tu trabajo de forma ' +
      'sencilla y sin compromiso.</p>' + 
      '<p> A todos nos gusta recomendar empresas y productos de calidad porque es una forma de ayudarnos a las personas ' +
      'que nos importan y queremos. Por desgracia, muchos agentes nunca se preparan y capacitan adecuadamente sobre cómo ' +
      'solicitar referidos de una manera eficaz para ambas partes. Lo que puede llevar a conversaciones como ' +
      'la siguiente: </p>' +
      '<p>A continuación te dejamos un par de ideas para que generes un gran marketing de referidos:</p>' +
      '<dl>' +
        '<dt>Cambia tu actitud acerca de los referidos.</dt>' +
        '<dd> Deja de creer que estás “pidiendo referidos” e imagina que estás buscando cómo ayudar a tus clientes, ' +
        'procurar su futuro y brindarles tranquilidad.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Crea un programa especial de bonos por referidos</dt>' +
        '<dd>Este es un programa cuyo objetivo es dar valor a los clientes que refieran tu agencia. Lo importante es ' +
        'que establezcas un procedimiento estándar, replicable y fácil de implementar.</dd>' +
        '<dd>Este tipo de programas ofrece muchas ventajas:</dd>' +
        '<ul>' +
          '<li>Será más fácil explicar y replicar para tus clientes.</li>' +
          '<li>Mayor posibilidad de prospectos.</li>' +
          '<li>Tus clientes tendrán motivación extra para referirte.</li>' +
        '</ul>' +
      '</dl>' +
      '<dl>' +
        '<dt>Se parte de la red de contactos de tus clientes</dt>' +
        '<dd>Si tus clientes no tienen tu información de contacto con ellos o ' +
        'nunca les responders, es poco probable que te referencien entre sus conocidos.</dd>' +
        '<dd>Asegúrate de estar entre los contactos telefónicos de tus clientes, después conoce a su  '+
        'familia y haz que ellos también te tengan en su teléfono.  Sé transparente, explica los beneficios de las pólizas ' +
        'e interésate en ellos.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Tarjetas de presentación.</dt>' +
        '<dd>Haz tarjetas de presentación con un espacio adicional para colocar </dd>' +
        '<dd>“Referido por____________________.” </dd>' +
        '<dd>En el espacio en blanco escribe el nombre de tu cliente y entregárselas para que las pasen a sus contactos.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Ten claro quién es tu cliente objetivo</dt>' +
        '<dd>Una razón por la que tus clientes dudan en referirte es porque no quieren quedar mal ' +
        'si recomiendan tus servicios y los precios resultan muy altos.</dd>' +
        '<dd>Elimina el riesgo explicándoles los tipos de clientes que manejas y las tarifas que tienes ' +
        'para ellos. Ten mucho cuidado con describirle a tus clientes un perfil diferente al de ellos, ' +
        'pues los hará dudar sobre si tus productos son adecuados para ellos.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Elimina los riesgos</dt>' +
        '<dd>Una de las principales razones por la que las personas dudan dar referidos es porque ' +
        'tienen miedo de quedar mal si sus contactos obtienen un mal servicio.Si eres grosero, ' +
        'no devuelves llamadas telefónicas, o tienes tarifas poco atractivas vas a hacer quedar mal a tu cliente.</dd>' +
        '<dd>Explícales a tus clientes cómo manejas estos contactos, haciendo énfasis en el trato ' +
        'especial que reciben así como la estrategia que desarrollas con ellos.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Utiliza LinkedIn</dt>' +
        '<dd>LinkedIn tiene una herramienta interesante que puede servirte para identificar referidos.  Cuando miras ' +
        'el perfil de alguien que no tienes en tu red, y ambos están conectados por una tercera persona, LinkedIn te ' +
        'mostrará quién es esa tercera persona.</dd>' +
        '<dd>Esto significa que puedes enviar un mensaje casual a la persona con la que estás conectado para solicitar ' +
        'que te presenten.</dd>' +
      '</dl>' +
      '<dl>' +
        '<dt>Educa a tus clientes sobre todos tus productos</dt>' +
        '<dd>Que un cliente compre un tipo de póliza, no significa que no debas asegurarte de que conozca los otros ' +
        'productos que tienes disponibles ya que puede contar con conocidos que necesiten una póliza en específico y ' +
        'podría recomendarte.</dd>' +
        '<dd>¡Mucha suerte con tu red de referidos!</dd>' +
      '</dl>' ;
    }
    this.showInformation = true;
    
    /*this._api.get_subcards(param.id).then(response=>{
      if(response){
        this.subtitles = response;
      }
    }).catch(error=>{
      console.log(error);
    })
    this._api.get_comments(param.id).then(response=>{
      if(response){
        this.comments = response;
      }
    }).catch(error=>{
      console.log(error);
    })*/
  }

  saveComment(){
    this.comment['author'] = 'Usuario';
    this.comment['priority'] = 0;
    this._api.save_comments(this.title['id'], this.comment).then(responses=>{
      this.comment['text'] = '';
      this._api.get_comments(this.title['id']).then(response=>{
        if(response){
          this.comments = response;
        }
      }).catch(error=>{
        console.log(error);
      })
    }).catch(error=>{
      console.log(error);
    })
  }
  
  return(){
    this.showInformation = false;
  }

}
