import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SEOService } from '../seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  schema = [
    {
      "@context" : "http://schema.org",
      "@type" : "Product",
      "name" : "SAAM",
      "image" : "https://saam-digital.miurabox.net/assets/service/recuadro-saam.png",
      "description" : "Sistema de administración de agentes de Miurabox para agentes de seguros que quieren lo mejor para sus clientes",
      "url" : "https://heysaam.com/servicios",
      "brand" : {
        "@type" : "Brand",
        "name" : "Saam"
      },
      "offers" : {
        "@type" : "Offer",
        "price" : "$600.00más IVA"
      }
    }, {
      "@context" : "http://schema.org",
      "@type" : "Product",
      "name" : "MULTICOTIZADOR",
      "image" : "https://saam-digital.miurabox.net/assets/service/recuadro-multicotizador.png",
      "description" : "Te permite cotizar y emitir con múltiples compañías de seguro en diferentes ramos. Tus emisiones se administran en SAAM",
      "url" : "https://heysaam.com/",
      "brand" : {
        "@type" : "Brand",
        "name" : "Saam"
      },
      "offers" : {
        "@type" : "Offer",
        "price" : "$600.00más IVA"
      }
    }
  ]

  constructor(public router: Router, private titleService: Title, private seoService: SEOService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Servicios Disponibles - Saam Digital');
    this.seoService.updateMetaTagsSerivce();
  }

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

}
