import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
   providedIn: 'root'
})
export class SEOService { 
    constructor(private meta: Meta) {
        meta.addTag({name: 'description', content: 'SAAM es el Asistente de Administración de seguros y fianzas para Agentes y brokers que brinda el mejor control operativo para su despacho.'});
    }

    updateMetaTagsAbout(){
        this.meta.updateTag({name: 'description', content: 'SAAM es el Asistente de Administración de seguros y fianzas para Agentes y brokers que brinda el mejor control operativo para su despacho.'});
    }

    updateMetaTagsSerivce(){
        this.meta.updateTag({name: 'description', content: 'SAAM además de facilitar la administración de seguros y fianzas para agentes y brokers, te permite cotizar y emitir con múltiples compañías de seguros.'});
    }

    updateMetaTagsBenefit(){
        this.meta.updateTag({name: 'description', content: 'Saam es intuitivo, lógico, portatil y ágil. Lo que necesites siempre estará a dos clics de distancia.'});
    }

    updateMetaTagsQuestion(){
        this.meta.updateTag({name: 'description', content: 'Auxiliándote a manejar tus pólizas, SAAM ayudará a tener tu información en un solo lugar. La información de nuestros clientes siempre estará protegida, a la mano, cifrada, y en la nube. Al tener tu información en SAAM, puedes acceder a ella desde cualquier dispositivo. Sí, es posible que tener todas tus antiguas pólizas antiguas en SAAM, y comenzar a expandir tu negocio. No, al estar la información desde la nube, puedes entrar desde cualquier dispositivo con Internet.'});
    }

    updateMetaTagsContact(){
        this.meta.updateTag({name: 'description', content: '¿Deseas saber más de nuestros servicios? Envíanos un mensaje o llámanos y uno de nuestros asesores te atenderá.'});
    }   
}