import { Component, OnInit } from '@angular/core';

declare var $ :any;

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {

  constructor() { 
    $(window).scroll(function() {    
      var altura_del_menu = $('.container-letter').outerHeight(true);
      if ($(window).scrollTop() >= 160){
        $('.container-letter').addClass('fixed');
        $('.wrapper').css('margin-top', (altura_del_menu) + 'px');
      }else{
        $('.container-letter').removeClass('fixed');
        $('.wrapper').css('margin-top', '0');
      }
    });
  }

  ngOnInit() {
  }

  scroll(element:HTMLElement){
    element.scrollIntoView();
  }

}
